import Swal from "sweetalert2";
<template>
    <v-app style="background: transparent">
        <v-card>
            <v-card-title>
                Mi historial de pagos y suscripciones
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-progress-linear v-if="loading"
                               indeterminate
            ></v-progress-linear>
            <div v-if="!loading">
                <v-tabs
                        v-model="tab"
                        background-color="primary"
                        centered
                        dark
                        icons-and-text>
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab v-for="(status ,i) in definedStatus" :key="i" :href="`#tab-${i}`">
                        {{status.name}}
                        <v-icon class="mr-1">{{statusIcon[i]}}</v-icon>
                    </v-tab>

                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(status ,i) in definedStatus"
                                :key="i"
                                :value="`tab-${i}`">
                        <v-data-table
                                class="elevation-1"
                                :loading="loading" loading-text="Loading... Please wait"
                                :headers="headers"
                                :items="getItems(status.value)"
                                :search="search">
                            <!--                <template v-slot:item.contratos="{ item }">-->
                            <!--                    <v-chip color="green" dark>{{ item.contratos }}</v-chip>-->
                            <!--                </template>-->
                        </v-data-table>
                    </v-tab-item>

                </v-tabs-items>
            </div>

        </v-card>
    </v-app>

</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import service from "@/service/transaction.service"


    export default {
        name: "PaymentList",

        data() {
            return {
                editing: true,
                isNew: false,
                dialog: false,
                loading: false,
                search: '',
                headers: [
                    {
                        text: 'Referencia',
                        align: 'left',
                        sortable: true,
                        value: 'referencia',
                    }, {
                        text: 'Fecha',
                        align: 'left',
                        sortable: true,
                        value: 'p2pfecha',
                    },{
                        text: 'Estado',
                        align: 'left',
                        sortable: true,
                        value: 'estado',
                    },
                    {
                        text: 'Subtotal',
                        align: 'left',
                        sortable: false,
                        value: 'subtotal',
                    }, {
                        text: 'IVA',
                        align: 'left',
                        sortable: false,
                        value: 'iva',
                    },
                    {
                        text: 'Total',
                        align: 'left',
                        sortable: true,
                        value: 'total',
                    }

                ],
                payments: [],
                currentItem: {},
                statusIcon: ['fa fa-clock',  'fa fa-check',  'fa fa-times', 'fa fa-list'],
                tab: 'tab-0',
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Documentos"},{title: "Pagos Realizados"}
            ]);
            this.getData();

        },
        methods: {
            getData() {
                this.payments = [];
                this.loading = true;
                this.definedStatus.forEach(x => {
                    service.getPaymentsByStatus(x.value).then(payments => {
                        const result = {
                            value: x.value,
                            payments: payments.data.data
                        };
                        this.loading = false;
                        this.payments.push(result);
                    });
                });

            },
            getItems(value) {
                const data = this.payments.filter(x => {
                    return x.value === value;
                });
                return data[0] ? data[0].payments : []
            },
        },
        computed: {
            definedStatus() {
                return service.getDefinedStatus();
            }
        }
    }
</script>

<style scoped>
</style>
